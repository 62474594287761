// CSS DO ARQUIVO LOGIN.JS //
.loginBackground{
    background-image: url('../login-background-dark.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    // border-radius: 100px 0px 0px 100px;
    width: 70%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1300px) {
      width: 60%;
    }
    @media only screen and (max-width: 1100px) {
      width: 55%;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .loginForm {
    width: 30%;
  height: 100vh;
  margin-right: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: margin-right 0.3s; /* Add a smooth transition effect */

  @media only screen and (max-width: 1300px) {
    margin-right: 60px;
    width: 40%;
  }
  @media only screen and (max-width: 1100px) {
    margin-right: 45px;
    width: 45%;
  }
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
  }
  }

// CSS DO ARQUIVO CARD-RENTAL-TODAY-REQUESTS.JS //
.reportHeader {
    background-image: url('../card-relatorio.jpg');
    width: '100%';
     height: 'auto';
     background-size: 'cover';
     background-repeat: no-repeat;
}
.reportDate{
    font-size: 16px;
    color: #053665;
    font-weight: 500;
}
.reportMeeting{
    font-size: 20px;
    color: #053665;
    font-weight: 600
}
.reportText{
    font-size: 20px;
    color: #053665;
    font-weight: 400;
}
.reportIcon{
  width: 24px;
  height: 24px;
 color: '#B0B0B0'
}

.reportMiniCardText{
  color: '#B0B0B0';
font-size: 16px;
 font-weight: 400;
 text-align: left;

}

.rentalCard {
  background-color: #82868b1f
}
// CSS DAS TELAS DE HISTÓRICO  //

.bg-v1 {
  background-color: #912e03;
}
.bg-v2 {
  background-color: #EF6325;
}
.bg-v3 {
  background-color: #FBA929;
}
.bg-v4 {
  background-color: #0a73b5;
}
.bg-v5 {
  background-color: #0aacb5;
}
.bg-v6 {
  background-color: #0AB599;
}
.bg-empty {
  background-color: #d5d5d5;
}
